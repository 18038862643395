import { Attachment, UUID } from '@/types/types';
import { Component } from './ComponentApi';
import { ProductType } from './ProductTypeApi';
import { ApiQueryKey } from '@/configs/apiQueryKey';
import { ImportRendererPayload } from '@/renderer/types/ExportPayload';
import EntityApi from './EntityApi';

export enum ProductStatus {
	NOT_ADDED,
	ADDDED_TO_CART,
}

export interface ProductComponent {
	id: UUID;
	product: Product;
	quantity: number;
	netPrice: number;
	grossPrice: number;
	componentData: Omit<Component, 'render'> & {
		render: string;
	};
}

export interface Product {
	id: UUID;
	netPrice: number;
	grossPrice: number;
	productType: ProductType;
	productComponents: ProductComponent[];
	status: ProductStatus;
	options: ImportRendererPayload;
	comment: string;
	updatedAt: string;
	image?: Attachment;
	createdAt: string;
}

class ProductApi extends EntityApi<Product> {
	constructor() {
		super(ApiQueryKey.products);
	}
}

export default new ProductApi();
