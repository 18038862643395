import React, { useState } from 'react';
import {
	Row,
	Col,
	CardBody,
	Card,
	Alert,
	Container,
	Form,
	Label,
	Input,
	CardTitle,
} from 'reactstrap';
import { useForm } from '@mantine/form';
import { Link } from 'react-router-dom';
import { useAuth } from '@/providers/AuthProvider';
import { LoadingOverlay } from '@mantine/core';
import { AppPaths } from '@/configs/appPaths';
import logoImg from '@/assets/images/logo_cropped.jpg';

export enum SubmitStatus {
	THROTTLE,
	LOADING,
	ERROR,
	SUCCESS,
}

export interface LoginValues {
	email: string;
	password: string;
	remember?: boolean;
}

const initialValues: LoginValues = {
	email: '',
	password: '',
	remember: true,
};

export const Login: React.FC = () => {
	const { login } = useAuth();

	const [status, setStatus] = useState(SubmitStatus.THROTTLE);
	const [resultMessage, setResultMessage] = useState('');

	const form = useForm({
		initialValues,
	});

	const handleSubmit = async (loginValues: LoginValues) => {
		setStatus(SubmitStatus.LOADING);
		setResultMessage('');

		const result = await login(loginValues);
		form.reset();

		if (result === SubmitStatus.ERROR) {
			setStatus(SubmitStatus.ERROR);
			setResultMessage(
				'Podane dane są nieprawidłowe. Sprawdź poprawność wprowadzonych danych'
			);
			return;
		}

		setStatus(SubmitStatus.THROTTLE);
	};

	return (
		<div className="account-pages">
			<Container className="position-absolute">
				<Row className="justify-content-center">
					<Col md={8} lg={6} xl={5}>
						<Card className="overflow-hidden mb-0">
							<LoadingOverlay visible={status === SubmitStatus.LOADING} />
							<div className="logo-bar">
								<img alt="logo" src={logoImg} />
							</div>
							<CardBody>
								<div className="p-2">
									<CardTitle className="mb-3">Zaloguj się do panelu</CardTitle>
									<Form
										className="form-horizontal"
										onSubmit={form.onSubmit(handleSubmit)}
									>
										{status === SubmitStatus.ERROR && (
											<Alert color="danger">{resultMessage}</Alert>
										)}
										<div className="mb-3">
											<Label className="form-label">Email</Label>
											<Input
												{...form.getInputProps('email')}
												required
												type="email"
												className="form-control"
												placeholder="Wpisz adres email"
												invalid={status === SubmitStatus.ERROR}
											/>
										</div>
										<div className="mb-3">
											<Label className="form-label">Hasło</Label>
											<Input
												{...form.getInputProps('password')}
												required
												type="password"
												className="form-control"
												placeholder="Wpisz hasło"
												invalid={status === SubmitStatus.ERROR}
											/>
										</div>
										<div className="form-check">
											<input
												{...form.getInputProps('remember', {
													type: 'checkbox',
												})}
												type="checkbox"
												className="form-check-input"
												id="remember"
											/>
											<label className="form-check-label" htmlFor="remember">
												Zapamiętaj mnie
											</label>
										</div>
										<div className="mt-3 d-grid">
											<button
												className="btn btn-primary btn-block"
												type="submit"
											>
												Zaloguj się
											</button>
										</div>
										<div className="mt-3 text-center">
											<Link
												to={`/${AppPaths.RESET_PASSWORD}`}
												className="text-muted"
											>
												<i className="mdi mdi-lock me-1" />
												Nie pamiętam hasła
											</Link>
										</div>
									</Form>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</div>
	);
};
