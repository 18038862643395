import { Attachment, DimensionAxis, Translations, UUID } from '@/types/types';
import { ProductTypePayloadWithFile } from '@/pages/authenticated/productTypes/CreateProductType';
import { ComponentCategory } from './ComponentCategoryApi';
import { ApiQueryKey } from '@/configs/apiQueryKey';
import ComponentApi from './ComponentApi';
import httpClient from './httpClient';
import EntityApi from './EntityApi';
import AppApi from './AppApi';

export enum ProductTypeViewType {
	DISTRIBUTION_BOARD = 'distribution-board',
	SHAFTS = 'shafts',
}

export interface ProductTypeDimensionSettings {
	min: number;
	max: number;
	step: number;
	pricePercentPerStep: number;
}

export interface ProductType {
	id: UUID;
	externalId: number;
	name: string;
	componentCategories: ComponentCategory[];
	viewType: ProductTypeViewType;
	price: number;
	translations: Translations<{
		name: string;
	}>;
	image?: Attachment;
	mainComponentCategory?: ComponentCategory;
	dimensionsSettings: Record<DimensionAxis, ProductTypeDimensionSettings>;
}

class ProductTypeApi extends EntityApi<ProductType> {
	constructor() {
		super(ApiQueryKey.productTypes);
		this.updateWithImage = this.updateWithImage.bind(this);
		this.createWithImage = this.createWithImage.bind(this);
		this.syncWordpress = this.syncWordpress.bind(this);
	}

	async createWithImage(payload: ProductTypePayloadWithFile) {
		let image: UUID | undefined;

		if (payload.image) {
			const imageResponse = await AppApi.createAttachment(payload.image);
			image = imageResponse.id;
		}

		const response = await httpClient.post<ProductType>(this.queryKey, {
			...payload,
			mainComponentCategory: { id: payload.mainComponentCategory },
			componentCategories: payload.componentCategories!.map((id) => ({ id })),
			image,
		});

		return response.data;
	}

	async updateWithImage(payload: Partial<ProductTypePayloadWithFile>) {
		let image: UUID | undefined | null;

		if (payload.image) {
			const imageResponse = await AppApi.createAttachment(payload.image);
			image = imageResponse.id;
		}

		const response = await httpClient.patch<ProductType>(
			`${this.queryKey}/${payload.id}`,
			{
				...payload,
				mainComponentCategory: { id: payload.mainComponentCategory },
				componentCategories: payload.componentCategories!.map((id) => ({ id })),
				image,
			}
		);
		return response.data;
	}

	async syncWordpress(id: UUID) {
		await httpClient.post(`${this.queryKey}/${id}/${ComponentApi.queryKey}`);
		return 1;
	}
}

export default new ProductTypeApi();
