import { QueryFunctionContext } from '@tanstack/react-query';
import { omitBy, trim } from 'lodash';
import {
	ApiFormattedResponseData,
	ApiPaginatedResponse,
	ApiQueryOptions,
	CustomFilters,
	UUID,
} from '@/types/types';
import { ApiQueryKey } from '@/configs/apiQueryKey';
import httpClient from './httpClient';

export interface BaseEntity {
	id: UUID;
}

abstract class EntityApi<TData extends BaseEntity> {
	readonly queryKey: ApiQueryKey;

	constructor(queryKey: ApiQueryKey) {
		this.queryKey = queryKey;
		this.update = this.update.bind(this);
		this.create = this.create.bind(this);
		this.delete = this.delete.bind(this);
	}

	async getAll({
		queryKey,
	}: QueryFunctionContext<
		[
			ApiQueryKey,
			Partial<Pick<ApiQueryOptions<TData>, 'search' | 'sortBy'>>?,
			CustomFilters?
		]
	>) {
		const params = omitBy(
			{
				search: trim(queryKey[1]?.search),
				sortBy: Object.values(queryKey[1]?.sortBy || {}).join(':'),
				limit: 0,
				...queryKey[2],
			},
			(value) => value === ''
		);

		const response = await httpClient.get<ApiPaginatedResponse<TData>>(
			queryKey[0],
			{
				params,
			}
		);

		return response.data.data;
	}

	async getPaginated({
		queryKey,
	}: QueryFunctionContext<
		[ApiQueryKey, ApiQueryOptions<TData>, CustomFilters?]
	>): Promise<ApiFormattedResponseData<TData>> {
		const params: Record<string, any> = {
			page: queryKey[1].page,
			limit: queryKey[1].limit,
			sortBy: `${queryKey[1].sortBy.key}:${queryKey[1].sortBy.order}`,
			search: trim(queryKey[1].search),
			...queryKey[2],
		};

		for (const key in params) {
			if (params[key] === '') delete params[key];
		}

		const response = await httpClient.get<ApiPaginatedResponse<TData>>(
			queryKey[0],
			{
				params,
			}
		);

		return {
			data: response.data.data,
			totalPages: response.data.meta.totalPages,
		};
	}

	async getOne({ queryKey }: QueryFunctionContext<[ApiQueryKey, UUID]>) {
		const response = await httpClient.get<TData>(
			`${queryKey[0]}/${queryKey[1]}`
		);

		return response.data;
	}

	async update(payload: Partial<TData>) {
		const response = await httpClient.patch<TData>(
			`${this.queryKey}/${payload.id}`,
			payload
		);

		return response.data;
	}

	async create(payload: Partial<TData>) {
		const response = await httpClient.post<TData>(this.queryKey, payload);

		return response.data;
	}

	async delete(id: UUID) {
		await httpClient.delete(`${this.queryKey}/${id}`);
	}
}

export default EntityApi;
