import { UUID, Translations, ComponentRelation } from '@/types/types';
import { QueryFunctionContext } from '@tanstack/react-query';
import { AttributeValue } from './AttributeValueApi';
import { ApiQueryKey } from '@/configs/apiQueryKey';
import EntityApi from './EntityApi';
import httpClient from './httpClient';

export enum AttributeViewType {
	SELECT,
	SWITCH,
	COLOR,
}

export interface Attribute {
	id: UUID;
	name: string;
	description?: string;
	listOrder: number;
	viewType: AttributeViewType;
	attributeValues: AttributeValue[];
	suffix?: string;
	relations: ComponentRelation[];
	translations: Translations<{
		name: string;
		description: string;
	}>;
}

class AttributeApi extends EntityApi<Attribute> {
	constructor() {
		super(ApiQueryKey.attributes);
	}

	async getValuesByAttribute({
		queryKey,
	}: QueryFunctionContext<[ApiQueryKey, UUID, ApiQueryKey]>) {
		const response = await httpClient.get<AttributeValue[]>(
			`${queryKey[0]}/${queryKey[1]}/${queryKey[2]}`
		);

		return response.data;
	}
}

export default new AttributeApi();
