import React, { lazy, useEffect } from 'react';
import { useAuth } from '@/providers/AuthProvider';
import { UnauthenticatedApp } from '@/components/routes/UnauthenticatedApp';
import { useLocation } from 'react-router-dom';
import { ErrorPage } from '@/pages/common/ErrorPage';
import { useTypedSelector } from '@/store/hooks';
import { Loading } from '@/components/ui/Loading';
import httpClient from '@/api/httpClient';

const AuthenticatedApp = lazy(
	() => import('@/components/routes/AuthenticatedApp')
);

export const App: React.FC = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'instant' });
	}, [pathname]);

	const { user } = useAuth();
	const { errorCode } = useTypedSelector((state) => state.app);

	if (errorCode) return <ErrorPage httpCode={errorCode} />;

	if (user && !httpClient.defaults.headers.common.Authorization)
		return <Loading />;

	return user ? <AuthenticatedApp /> : <UnauthenticatedApp />;
};
