import {
	ApiPaginatedResponse,
	ApiQueryOptions,
	Attachment,
	Translations,
	UUID,
} from '@/types/types';
import { ComponentCategory } from './ComponentCategoryApi';
import { AttributeValue } from './AttributeValueApi';
import { QueryFunctionContext } from '@tanstack/react-query';
import { ApiQueryKey } from '@/configs/apiQueryKey';
import httpClient from './httpClient';
import EntityApi from './EntityApi';
import AppApi from './AppApi';

export interface Component {
	id: UUID;
	name: string;
	componentIndex: string;
	render?: Attachment;
	pricePercentage: number;
	componentCategory: ComponentCategory;
	attributeValues: AttributeValue[];
	translations: Translations<{
		name: string;
	}>;
}

export type ExportComponentItemsResponse = {
	success: Component[];
	error: never[];
};

class ComponentApi extends EntityApi<Component> {
	constructor() {
		super(ApiQueryKey.components);
		this.changeFile = this.changeFile.bind(this);
	}

	async getByComponentCategory({
		queryKey,
	}: QueryFunctionContext<
		[ApiQueryKey, UUID, Pick<ApiQueryOptions<Component>, 'page' | 'limit'>]
	>) {
		const response = await httpClient.get<ApiPaginatedResponse<Component>>(
			queryKey[0],
			{
				params: {
					...queryKey[2],
					'filter.componentCategory.id': queryKey[1],
				},
			}
		);

		return {
			data: response.data.data,
			totalPages: response.data.meta.totalPages,
		};
	}

	async changeFile({ id, file }: { id: UUID; file: File }) {
		const attachment = await AppApi.createAttachment(file);

		const response = await httpClient.patch<Component>(
			`${(this, this.queryKey)}/${id}`,
			{
				id,
				render: { id: attachment.id },
			}
		);

		return response.data;
	}
}

export default new ComponentApi();
