import { Attachment, Translations, UUID } from '@/types/types';
import { ComponentCategoryForm } from '@/pages/authenticated/componentCategories/CreateComponentCategory';
import { Component } from './ComponentApi';
import { ProductType } from './ProductTypeApi';
import { ApiQueryKey } from '@/configs/apiQueryKey';
import { AttributeValue } from './AttributeValueApi';
import httpClient from './httpClient';
import EntityApi from './EntityApi';
import AppApi from './AppApi';

type ComponentPayloadWithFile = ComponentCategoryForm & {
	id?: UUID;
	image?: File;
};

export enum ComponentCategoryType {
	BASE = 'base',
	COMPONENTS = 'components',
	ACCESSORIES = 'doors',
}

export interface ComponentCategory {
	id: UUID;
	name: string;
	attributeValues: AttributeValue[];
	productTypes: ProductType[];
	description: string;
	components: Component[];
	listOrder: number;
	image?: Attachment;
	type: ComponentCategoryType;
	componentsCount: number;
	isConfigurable: boolean;
	chosenAttributeValues: Record<UUID, UUID | ''>;
	translations: Translations<{
		name: string;
		description: string;
	}>;
}

class ComponentCategoryApi extends EntityApi<ComponentCategory> {
	constructor() {
		super(ApiQueryKey.componentCategories);
		this.updateWithImage = this.updateWithImage.bind(this);
		this.createWithImage = this.createWithImage.bind(this);
		this.massComponentItemsUpdate = this.massComponentItemsUpdate.bind(this);
	}

	async createWithImage(payload: ComponentPayloadWithFile) {
		let image: string | undefined = undefined;

		if (payload.image) {
			const imageResponse = await AppApi.createAttachment(payload.image);
			image = imageResponse.id;
		}

		const response = await httpClient.post<ComponentCategory>(
			this.queryKey,
			Object.assign(payload, {
				image,
				attributeValues: payload.attributeValues.map((attributeValue) => ({
					id: attributeValue,
				})),
			})
		);
		return response.data;
	}

	async updateWithImage(payload: Partial<ComponentPayloadWithFile>) {
		let image: string | undefined = undefined;

		if (payload.image) {
			const imageResponse = await AppApi.createAttachment(payload.image);
			image = imageResponse.id;
		}

		const response = await httpClient.patch<ComponentCategory>(
			`${this.queryKey}/${payload.id}`,
			Object.assign(payload, {
				image,
				attributeValues: payload.attributeValues?.map((attributeValue) => ({
					id: attributeValue,
				})),
			})
		);
		return response.data;
	}

	async massComponentItemsUpdate({
		componentCategoryId,
		selectedAttributeValues,
		payload,
		file,
		asset,
	}: {
		componentCategoryId: UUID;
		selectedAttributeValues: UUID[];
		payload: Partial<Component>;
		file?: File;
		asset?: string;
	}) {
		const payloadWithImage = payload;

		if (file) {
			const attachmentResponse = await AppApi.createAttachment(file as File);
			payloadWithImage.render = attachmentResponse.id as unknown as Attachment;
		}

		if (asset) Object.assign(payloadWithImage, { asset: { id: asset } });

		await httpClient.patch(
			`${this.queryKey}/${componentCategoryId}/${ApiQueryKey.components}`,
			payloadWithImage,
			{
				params: {
					selectedValues: selectedAttributeValues.join(','),
				},
			}
		);
	}
}

export default new ComponentCategoryApi();
