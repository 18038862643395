export enum AppPaths {
	LOGIN = 'login',
	RESET_PASSWORD = 'reset-password',
	SET_PASSWORD = 'set-password',

	DASHBOARD = 'dashboard',

	PRODUCTS = 'products',

	PRODUCT_TYPES = 'product-types',
	COMPONENT_CATEGORIES = 'component-categories',
	COMPONENTS = 'components',
	ATTRIBUTES = 'attributes',
	ATTRIBUTE_VALUES = 'attribute-values',

	ADMINS = 'admins',
	ROLES = 'roles',
}
